<template>
  <section id="pricing-plan">
    
    <div v-for="(data, index) in DataPrice" :key="index">
      <products-card
        v-if="!isLoading"
        :dataDetail="data"
        :title="index"
        :subscribe="data['Monthly Fee']"
        :action="actionPage"
        :currentNtivi="paketNtivi"
        :productsNtivi="allNtivi"
      />
    </div>
    <loading v-if="isLoading"></loading>
    <!-- pricing free trial -->
    <div class="pricing-free-trial">
      <b-row>
        <b-col lg="10" offset-lg="3" class="mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                {{ $t("form.page.subscription.Closing Statement") }}
              </h3>
              <h5>{{ $t("form.page.subscription.Sub Closing Statement") }}</h5>
              <br />
              <div>
                <h4>+62 (21) 8082 1777</h4>
                <span class="text-body">{{
                  $t("form.page.subscription.Last Closing Statement")
                }}</span>
              </div>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="
                require('@/assets/images/illustration/pricing-Illustration.svg')
              "
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <!-- pricing faq -->
    <!-- <div class="pricing-faq">
      <h3 class="text-center">FAQ's</h3>
      <p class="text-center">Let us help answer the most common questions.</p>
      <b-row class="py-2">
        <b-col lg="10" offset-lg="2" class="mx-auto">
          <app-collapse accordion type="margin">
            <app-collapse-item
              v-for="(data, index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div> -->
    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import ProductsCard from "./ProductsCard.vue";
import axios from "@axios";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Loading from "@core/components/loading/Loading.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    ProductsCard,
    Swiper,
    SwiperSlide,
    BCardCode,
    Loading,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      customerCode: localStorage.getItem("customer_code"),
      status: "monthly",
      monthlyPlanShow: true,
      isLoading: false,
      actionPage: null,
      DataPrice: {},
      DataCurrent: [],
      paketNtivi: null,
      allNtivi: []
    };
  },
  created() {
    //this.$http.get('/pricing/data').then(res => { this.pricing = res.data })
  },
  methods: {
    init(action) {
      this.isLoading = true;
      this.getCurrentProduct();
      this.actionPage = action;

      if (action !== "Add" && action !== "Upgrade" && action !== "Remove") {
        this.$router.push({ name: "error-404" });
        //this.actionPage = "Add";
      }

      this.allChannelNtivi();
    },
    getProduct() {
      axios
        .get("products/all")
        .then((response) => {
          this.DataPrice = response.data.data;
          // Mengambil 'all Ntivi' 
          const listChn = this.allNtivi;

          // Filter array untuk mengambil channel_count & channel_list
          this.DataPrice.TV['Monthly Fee'].forEach(fe => {
              const abc = listChn.filter(c => c.product_code == fe.product_code);
              if(abc.length > 0) {
                fe.channel_count = abc[0].channel_count;
                fe.channel_list = abc[0].channel_list;
              }
          });
        })
        .catch((error) => {
          if (error.response.data.message == "Expired token") {
            localStorage.clear();
            this.$router.push({ name: "login" });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Products",
                icon: "EditIcon",
                variant: "success",
                text: "Session Expired, Please Login Again",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Products",
                icon: "EditIcon",
                variant: "success",
                text: error.response.data.message,
              },
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    allChannelNtivi() {
      axios.get("third_party/ntivi_channel").then((response) => {
        this.allNtivi = response.data.data; 
      }).catch((error) => {}).finally(() => { this.getProduct(); });
    },
    tooglePlan() {
      if (this.status === "monthly") {
        this.monthlyPlanShow = true;
      } else {
        this.monthlyPlanShow = false;
      }
    },
    getCurrentProduct(){
      axios.get("products/current-package-ntivi").then((response) => {
        this.DataCurrent = response.data.data;
        this.paketNtivi = response.data.product_category_id;
      }).catch((error) => {
        if (error.response.data.message == "Expired token") {
          localStorage.clear();
          this.$router.push({ name: "login" });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Products",
              icon: "EditIcon",
              variant: "success",
              text: "Session Expired, Please Login Again",
            },
          });
        } 
      }).finally(() => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    let route = this.$route.path.split("/");
    this.init(route[2]);
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
